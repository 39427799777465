import "modern-normalize/modern-normalize.css"
import "./src/css/styles.css"
import "./src/assets/custom_styles.css"
import "maplibre-gl/dist/maplibre-gl.css"
import "photoswipe/dist/photoswipe.css"
import "react-toastify/dist/ReactToastify.css"
import React from "react"
import axios from "axios"
import { ToastContainer } from "react-toastify"

axios.defaults.baseURL = process.env.GATSBY_API_URL
axios.defaults.withCredentials = true

// get CSRF Cookie
// this is mainly used for form submissions
axios.get("/sanctum/csrf-cookie")

export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <ToastContainer />
  </>
)
